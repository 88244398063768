<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
				v-model="dialogEdit"
				max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                    Добавить акт
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Название"
                                v-model="editedItem.name"
                                @change="haveEditorChanges = true"
                            >
                            </v-text-field>
                        </v-card-text>

                        <v-card-text>
                            <v-dialog
                                ref="pubDialog"
                                v-model="datePubDialog"
                                :return-value.sync="editedItem.datePub"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.datePub"
                                    label="Дата размещения"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="haveEditorChanges = true"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.datePub"
                                    scrollable
                                    locale="ru-RU"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="datePubDialog = false"
                                >
                                    Отмена
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.pubDialog.save(editedItem.datePub)"
                                >
                                    Ок
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-card-text>

                        <v-card-text>
                            <v-dialog
                                ref="finishDialog"
                                v-model="dateFinishDialog"
                                :return-value.sync="editedItem.dateFinish"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.dateFinish"
                                    label="Дата окончания обсуждения"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="haveEditorChanges = true"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.dateFinish"
                                    scrollable
                                    locale="ru-RU"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="dateFinishDialog = false"
                                >
                                    Отмена
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.finishDialog.save(editedItem.dateFinish)"
                                >
                                    Ок
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-card-text>

                        <v-card-text>
                            <v-text-field
                                label="Предложения"
                                v-model="editedItem.ideas"
                                @change="haveEditorChanges = true"
                            >
                            </v-text-field>
                        </v-card-text>

                        <v-card-text>
                            <v-text-field
                                label="Решение"
                                v-model="editedItem.decesion"
                                @change="haveEditorChanges = true"
                            >
                            </v-text-field>
                        </v-card-text>

                        <v-card-text>
                            <v-file-input
                                label="Документ"
                                v-model="editedItem.newFile"
                                v-if="editedItem.fileName.length == 0"
                                @change="haveEditorChanges = true"
                            ></v-file-input>
                            <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.fileName.length > 0">
                                <v-icon
                                    x-large
                                >
                                    mdi-file-document-outline
                                </v-icon>
                                <span>
                                    <a
                                        :href="apiUrl + '/uploads/activity/' + editedItem.fileName"
                                    >
                                        {{ editedItem.fileName }}
                                    </a>
                                </span>
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <file-uploader ref="uploader" />
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление акта</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить выбранный акт?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить акт"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить акт"
            >
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import FileUploader from '@c/FileUploader.vue';
export default {
    name: "Activities",
    components: {
        FileUploader
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API,
            list: [],
            datePubDialog: false,
            dateFinishDialog: false,
            user: 0,
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Название',
                    sortable: false,
                },
                {
                    value: 'datePub',
                    text: 'Дата размещения',
                    sortable: false,
                },
                {
                    value: 'dateFinish',
                    text: 'Дата окончания обсуждения',
                    sortable: false,
                },
                {
                    value: 'ideas',
                    text: 'Предложения',
                    sortable: false,
                },
                {
                    value: 'decesion',
                    text: 'Решение',
                    sortable: false,
                },
                {
                    value: 'fileName',
                    text: 'Файл',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [10, 20, 50, 100, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                datePub: (new Date(Date.now())).toISOString().substr(0, 10),
                dateFinish: (new Date(Date.now())).toISOString().substr(0, 10),
                ideas: '',
                decesion: '',
                fileName: '',
                filePath: '',
                newFile: {},
            },
            dialogRemove: false,
            itemToRemove: -1,
            haveEditorChanges: false,
        };
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.getList(this.pagination.page);
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый акт' : 'Редактирование акта #' + this.editedItem.id;
        },
    },
    methods: {
        tablePagination (data) {
            this.getList(data.page);
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/activities?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                list.forEach((singleNew, index) => {
                    const dt = new Date(list[index].datePub);
                    list[index].datePub = ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (parseInt(dt.getMonth()) + 1)).slice(-2) + '.' + dt.getFullYear();
                    const dt2 = new Date(list[index].dateFinish);
                    list[index].dateFinish = ('0' + dt2.getDate()).slice(-2) + '.' + ('0' + (parseInt(dt2.getMonth()) + 1)).slice(-2) + '.' + dt2.getFullYear();
                });
                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            // почему-то при конвертировании даты с 0-ми часами, минутами и секундами выдаёт предыдущий день
            try {
                let r = await this.$api(`/admin/activities/${item.id}`);
                this.editedItem = Object.assign({}, r);
                this.editedItem.datePub = new Date(new Date(this.editedItem.datePub) + (new Date()).getTimezoneOffset() * 1).toISOString().substr(0, 10);
                this.editedItem.dateFinish = new Date(new Date(this.editedItem.dateFinish) + (new Date()).getTimezoneOffset() * 1).toISOString().substr(0, 10);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/activities/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = {
                    id: -1,
                    name: '',
                    datePub: (new Date(Date.now())).toISOString().substr(0, 10),
                    dateFinish: (new Date(Date.now())).toISOString().substr(0, 10),
                    ideas: '',
                    decesion: '',
                    fileName: '',
                    filePath: '',
                    newFile: {},
                };
                this.editedIndex = -1;
            });
        },
        async uploadFile () {
            if ((this.editedItem.newFile != null) && (!isNaN(this.editedItem.newFile.size))) {
                const needUpload = [];
                this.editedItem.newFile.id = this.editedItem.id;
                needUpload.push(this.editedItem.newFile);
                const file_name = await this.$refs.uploader.upload(needUpload);
                this.editedItem.filePath = file_name[0].tmp;
                this.editedItem.fileName = this.editedItem.newFile.name;
            }
        },
        async save () {
            await this.uploadFile();
            if (this.editedItem.fileName.length === 0) {
                this.$error('Акт не может быть сохранён без загрузки файла');
                return;
            }

            if (this.editedItem.name.length === 0) {
                this.$error('Акт не может быть сохранён без названия');
                return;
            }

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/activities/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            datePub: this.editedItem.datePub,
                            dateFinish: this.editedItem.dateFinish,
                            ideas: this.editedItem.ideas,
                            decesion: this.editedItem.decesion,
                            fileName: this.editedItem.fileName,
                            filePath: this.editedItem.filePath,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/activities", {
                        method: "POST",
                        body: {
                            name: this.editedItem.name,
                            datePub: this.editedItem.datePub,
                            dateFinish: this.editedItem.dateFinish,
                            ideas: this.editedItem.ideas,
                            decesion: this.editedItem.decesion,
                            fileName: this.editedItem.fileName,
                            filePath: this.editedItem.filePath,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>
